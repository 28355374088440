import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import LoadingSpinner from "../../shared/loadingSpinner";
import { getAllProjects } from "../../shared/services";
import RenderHitPerson from "./RenderHitPerson";
import ProjectDetails from "./ProjectDetails/ProjectDetails";
import RenderProjects from "./RenderProjects";
import SearchBar from "./SearchBar";
import ProjectStateFilter from "./ProjectStateFilter";

//https://flowbite.com/docs/components/tables/
//Edit table with modal
//Striped rows color

export default function Projects() {
  const [openEvents, setOpenEvents] = useState(false);
  const [openBasztatas, setOpenBasztatas] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});
  const [column, setColumn] = useState("");
  const [checkBoxClicked] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const {
    isFetching: loadingProjects,
    data: dataProjects,
    refetch: refetchProjects,
  } = useQuery("projects", getAllProjects, { cacheTime: 0 });

  useEffect(() => {
    if (dataProjects) {
      const previousFilter = [...filteredData];

      if (previousFilter.length > 0) {
        setFilteredData(
          dataProjects.filter((item) =>
            previousFilter.some((project) => project._id === item._id)
          )
        );
      } else {
        setFilteredData(dataProjects);
      }
    }
  }, [dataProjects]);

  useEffect(() => {
    if (!openEvents && !openBasztatas) {
      refetchProjects();
    }
  }, [openEvents, openBasztatas]);

  useEffect(() => {
    refetchProjects();
  }, [checkBoxClicked]);

  return (
    // If you have absolute divs, you always need the set the parent
    // element "relative", otherwise your app will break
    <div className="relative">
      <div className="flex flex-row items-center">
        <SearchBar
          projectData={dataProjects}
          setFilteredData={setFilteredData}
        />

        <ProjectStateFilter
          projectData={dataProjects}
          setFilteredData={setFilteredData}
          openEvents={openEvents}
        />

        {!!+process.env.REACT_APP_PROD ? null : (
          <div className="flex w-[700px] h-full items-center justify-center">
            <h1 className="font-bold text-xl">*** DEVELOPMENT ENVIROMENT***</h1>
          </div>
        )}
      </div>

      {filteredData ? (
        <>
          <RenderProjects
            setOpenEvents={setOpenEvents}
            projectData={filteredData}
            setSelectedProject={setSelectedProject}
            setOpenBasztatas={setOpenBasztatas}
            setColumn={setColumn}
            refetchProjects={refetchProjects}
          />
        </>
      ) : (
        <div className="pt-10">
          <LoadingSpinner />
        </div>
      )}

      {!loadingProjects && filteredData ? (
        <>
          <ProjectDetails
            openEvents={openEvents}
            setOpenEvents={setOpenEvents}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
          <RenderHitPerson
            openBasztatas={openBasztatas}
            setOpenBasztatas={setOpenBasztatas}
            selectedProject={selectedProject}
            column={column}
          />
        </>
      ) : null}
    </div>
  );
}
