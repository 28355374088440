import React, { useEffect, useState, useRef } from "react";
import { FaRegWindowClose } from "react-icons/fa";
import LoadingSpinner from "../../../shared/loadingSpinner";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useQuery } from "react-query";
import {
  editProject,
  getAllPersonsGrouped,
  getNote,
  saveNote,
} from "../../../shared/services";
import ProjectDetailsEvents from "./ProjectDetailsEvents";
import ProjectDetailsNotes from "./ProjectDetailsNotes";
import ProjectDetailstDesignTable from "./ProjectDetailstDesignTable";
import { toast } from "react-toastify";
import OutsideClick from "../OutsideClick";
import ProjectDetailsEdit from "./ProjectDetailsEdit";

export default function ProjectDetails({
  openEvents,
  setOpenEvents,
  selectedProject,
  setSelectedProject,
}) {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [notes, setNotes] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);

  const boxRef = useRef(null);
  const boxOutsideClick = OutsideClick(boxRef);

  const { data: dataNote } = useQuery(
    ["project_notes", selectedProject["uuid_projekt"]],
    () => getNote(selectedProject["uuid_projekt"]),
    { enabled: openEvents, cacheTime: 0 }
  );

  //Close projectDetails when clicking outside
  useEffect(() => {
    // On init boxOutsideClick is undefined, it only gets value when we open the projectDetails
    if (!boxOutsideClick && boxOutsideClick !== undefined) {
      onExit();
    }
  }, [boxOutsideClick]);

  useEffect(() => {
    setNotes(dataNote);
  }, [dataNote]);

  const { isFetching: loadingPersons, data: dataPersons } = useQuery(
    "persons_grouped",
    getAllPersonsGrouped,
    { enabled: openEvents, cacheTime: 0 }
  );

  async function onExit() {
    try {
      setOpenEvents(false);

      const saveNotePromise = saveNote({
        projectUID: selectedProject["uuid_projekt"],
        note: notes,
      });

      const editProjectPromise = editProject({ project: selectedProject });

      await Promise.all([saveNotePromise, editProjectPromise]);

      // setLoadingSave(false);
    } catch (err) {
      toast.error("Error: " + err.message);
      setLoadingSave(false);
    }
  }

  function RenderHeader() {
    return (
      <div>
        <div className="flex justify-between">
          <div className="flex flex-row">
            <div className="w-12">
              <div
                className={`${
                  selectedProject["állapot"] === "Új"
                    ? "bg-blue-500"
                    : selectedProject["állapot"] === "Folyamatban"
                    ? "bg-yellow-500"
                    : "bg-green-500"
                } w-8 h-8 rounded-full shadow-md`}
              />
            </div>
            <label className="text-2xl font-bold text-center" type="labe">
              {selectedProject["név"]}
            </label>
          </div>

          {/* Right side buttons */}
          <div className="flex flex-row">
            {loadingSave ? (
              <div className="pr-5">
                <LoadingSpinner className={"w-3 h-3"} />
              </div>
            ) : (
              <Tippy content="Bezárás" delay={300}>
                <button
                  onClick={() => {
                    onExit();
                  }}
                >
                  <FaRegWindowClose className="w-11 h-8 fill-red-600 hover:fill-red-300 hover:rotate-6" />
                </button>
              </Tippy>
            )}
          </div>
          {/* Right side buttons */}
        </div>

        <div className="flex flex-row gap-8 justify-center">
          <div className="flex flex-row gap-2">
            <p className="font-bold text-xl">HRSZ:</p>
            <p className="text-xl">{selectedProject["hrsz"]}</p>
          </div>

          <div className="flex flex-row gap-2">
            <p className="font-bold text-xl">Tel:</p>
            <p className="text-xl">{selectedProject["telefon"]}</p>
          </div>

          <div className="flex flex-row gap-2">
            <p className="font-bold text-xl">Email:</p>
            <p className="text-xl">{selectedProject["email"]}</p>
          </div>

          <div className="flex flex-row gap-2">
            <p className="font-bold text-xl">Utca:</p>
            <p className="text-xl">{selectedProject["cím"]}</p>
          </div>
        </div>
      </div>
    );
  }

  function RenderSelector() {
    const BUTTONS = [
      "Események és jegyzetek",
      "Tervezői művezetés",
      "Adatok/Szerkesztés",
    ];

    return (
      <div className="flex justify-evenly pt-5 pb-5">
        {BUTTONS.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              setSelectedMenu(index);
            }}
            className={`${
              selectedMenu === index ? "bg-blue-600" : "bg-gray-400"
            } w-[22%] items-center justify-center font-poppins`}
            type="button"
          >
            {item}
          </button>
        ))}
      </div>
    );
  }

  return (
    <>
      <div
        className={`${
          openEvents ? "w-full h-full" : "w-0 h-0"
        } top-0 backdrop-blur-sm absolute`}
        ref={boxRef}
      ></div>

      {selectedProject ? (
        <div
          className={`${
            openEvents ? "w-[85%]" : "w-0"
          } h-[100%] duration-500 absolute top-0 right-0 rounded-md shadow-md bg-slate-200 z-50`}
        >
          <div
            className={`${
              openEvents ? "" : "scale-0"
            }  flex flex-col m-4 justify-center`}
          >
            <RenderHeader />
            <RenderSelector />

            {selectedMenu === 0 ? (
              <>
                <div className="flex w-full h-screen">
                  <div className="flex w-[80%] h-[70%]">
                    <ProjectDetailsEvents
                      selectedProject={selectedProject}
                      openEvents={openEvents}
                    />
                  </div>
                  <div className="flex w-[20%] h-[70%]">
                    <ProjectDetailsNotes notes={notes} setNotes={setNotes} />
                  </div>
                </div>
              </>
            ) : selectedMenu === 1 ? (
              <ProjectDetailstDesignTable
                selectedProject={selectedProject}
                openEvents={openEvents}
              />
            ) : (
              <>
                {!loadingPersons && dataPersons ? (
                  <ProjectDetailsEdit
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    setOpenEvents={setOpenEvents}
                  />
                ) : (
                  <LoadingSpinner />
                )}
              </>
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}
