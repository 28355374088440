import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import React, { useCallback, useMemo, useState } from "react";
import { editCheckBox, getAllColumns } from "../../shared/services";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { FaWindowMinimize } from "react-icons/fa";
import { truncate } from "../../functions/helperFunctions";
import { AgGridReact } from "ag-grid-react";
import { themeBalham } from "ag-grid-community";

const myTheme = themeBalham.withParams({
  accentColor: "#FF6A3D",
  headerFontSize: "1.1rem",
  headerTextColor: "#000",
});

const COMMON_CELL_STYLE = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1rem", // Adjust the font size as needed
};

export default function RenderProjects({
  setOpenEvents,
  projectData,
  setSelectedProject,
  setOpenBasztatas,
  setColumn,
  refetchProjects,
}) {
  const [rowId, setRowId] = useState(-1);

  const { isFetching: loadingColumns, data: columns } = useQuery(
    "columns",
    getAllColumns
  );

  // Memoize column definitions to avoid recalculating on every render
  const columnDefs = useMemo(() => {
    if (!columns) return [];

    return columns
      .filter((col) => col.visible)
      .sort((a, b) => a.order - b.order)
      .map((col) => {
        const baseColumn = {
          headerName: col.display_name,
          field: col.col_name,
          sortable: true,
          filter: true,
          // floatingFilter: true,
          width: col.col_name === "név" ? 250 : 140,
          cellStyle:
            col.col_name === "név"
              ? { fontSize: "1rem", paddingTop: "0.2rem" }
              : COMMON_CELL_STYLE,
        };

        if (col.col_name === "állapot") {
          return {
            ...baseColumn,
            filter: "agSetColumnFilter",
            filterParams: {
              values: ["Új", "Folyamatban", "Kész"],
              debounceMs: 0,
              defaultToNothingSelected: true,
            },
            cellRenderer: (params) => {
              const value = params.value;
              const color =
                value === "Új"
                  ? "bg-blue-500"
                  : value === "Folyamatban"
                  ? "bg-yellow-500"
                  : "bg-green-500";
              return params.data._id === rowId ? (
                <button
                  className="bg-orange-500 text-white rounded-md p-3"
                  onClick={() => {
                    setSelectedProject(params.data);
                    setOpenEvents((prev) => !prev);
                  }}
                >
                  Megnyitás
                </button>
              ) : (
                <div className={`w-6 h-6 rounded-full ${color}`}></div>
              );
            },
          };
        }

        if (col.type === "checkbox") {
          return {
            ...baseColumn,
            width: 90,
            cellRenderer: (params) => {
              return (
                <input
                  className="w-5 h-5"
                  checked={Boolean(params.data[col.col_name])} // Use params.data for correct value access
                  type="checkbox"
                  onChange={(e) => {
                    onClickCheckBox(
                      params.data._id,
                      col.col_name,
                      e.target.checked
                    );
                  }}
                />
              );
            },
          };
        }

        if (col.type === "szakag") {
          return {
            ...baseColumn,
            cellRenderer: (params) => {
              const item = params.data;
              const colName = params.colDef.field;
              if (item[colName] === "Nincs még") {
                return (
                  <FaWindowMinimize className="w-6 h-6 pb-2 fill-gray-300" />
                );
              } else {
                const hits = item.hits[colName];
                const buttonClass =
                  hits.length < 1
                    ? ""
                    : !hits[0].state
                    ? "bg-green-500"
                    : hits[0].len === 1
                    ? "bg-red-300"
                    : hits[0].len === 2
                    ? "bg-red-500"
                    : hits[0].len === 3
                    ? "bg-red-800"
                    : "bg-red-700";
                return (
                  <Tippy content="Basztatás" delay={300}>
                    <button
                      onClick={() => onClickPerson(item, colName)}
                      className={`${buttonClass} rounded-sm shadow-sm w-full`}
                    >
                      {truncate(item[colName], 20)}
                    </button>
                  </Tippy>
                );
              }
            },
            width: 200,
          };
        }

        return baseColumn;
      });
  }, [columns, rowId]);

  const onClickPerson = useCallback((item, column) => {
    setOpenBasztatas(true);
    setSelectedProject(item);
    setColumn(column);
  }, []);

  const onClickCheckBox = useCallback(async (projectUID, colName, value) => {
    await editCheckBox({
      projectUID: projectUID,
      colName: colName,
      value: value,
    })
      .then(() => {
        toast.success("Elmentve!", { toastId: "success25" });
        refetchProjects();
      })
      .catch((err) => {
        toast.error("Hiba!\n" + err);
      });
  }, []);

  const handleCellMouseOver = useCallback((params) => {
    setRowId(params.data._id);
  }, []);

  const handleCellMouseOut = useCallback(() => {
    setRowId(-1);
  }, []);

  if (loadingColumns) {
    return;
  }

  return (
    <div className="flex h-screen">
      <div className="h-[90%] w-full m-5">
        <AgGridReact
          rowData={projectData}
          columnDefs={columnDefs}
          theme={myTheme}
          rowHeight={40}
          onCellMouseOver={handleCellMouseOver}
          onCellMouseOut={handleCellMouseOut}
        />
      </div>
    </div>
  );
}
